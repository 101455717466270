import { ContentWrapper } from "./articles/components/ContentWrapper";
import {
  Divider,
  Paragraph,
  Subtitle,
  Title,
} from "./articles/components/Typography";

export const LandingPageArticle = () => {
  return (
    <ContentWrapper>
      <Paragraph center>
        Každý člověk v sobě nese{" "}
        <span className="text-xl">vlastní původní</span> podstatu
      </Paragraph>

      <Paragraph center>
        Avšak vlivem životních událostí se tato může stát více či méně zakalenou
        <br />a stává se nepřirozenou
      </Paragraph>

      <Paragraph center>
        Proto je třeba narovnat
        <br />
        své hodnoty a způsoby do správné polohy v jistých oblastech života
      </Paragraph>

      <Paragraph center>
        Jak úměrně rozložit svou sílu tak, aby byla každá část vyvážena s
        ostatními?
      </Paragraph>

      <Paragraph center>
        Úkolem každého z nás je tak najít onu{" "}
        <span className="text-xl">správnou míru</span> ve všem, co děláme,
        <br />a která je <span className="text-xl">vlastní</span> naší podstatě
      </Paragraph>

      <Paragraph center>
        Mnohdy totiž stačí vyladit jen <span className="text-xl">jednu</span>{" "}
        část, aby se člověk cele zharmonizoval
      </Paragraph>

      <Divider center />

      <Paragraph center>
        Cítím silnou potřebu pomáhat těm, kteří v tomto hledají pomoc
      </Paragraph>

      <Title center>Najděme spolu ten nejharmoničtější bod</Title>
      <div className="bg-gray-300 p-3 w-fit bg-opacity-30 mx-auto space-y-1">
        <a aria-labelledby="More here" href="/new-today?open=harmony">
          <Subtitle>-více zde-</Subtitle>
        </a>
        <p className="-mt-3 text-[#857e71] text-2xl">
          <a href="/new-today?open=harmony">Harmonizace duše</a>
        </p>
      </div>
    </ContentWrapper>
  );
};
