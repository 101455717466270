import { ContentWrapper } from "../components/articles/components/ContentWrapper";
import {
  Paragraph,
  Subtitle,
  Title,
} from "../components/articles/components/Typography";
import AudioPlayer from "../components/AudioPlayer";
import { Page } from "../components/Page";

export const HarmonySpiritPage = () => {
  return (
    <Page
      backgroundSrc="https://files.catbox.moe/8pxd96.jpg"
      customOpacity={70}
      className="font-serif"
    >
      <div className="lg:w-[900px] w-fit mx-auto">
        <ContentWrapper>
          <Title center>
            1. část <br />
            Svět slučování <br />
            aneb <br />
            Chemie jako poznávání jemnocitu <br />
          </Title>

          <div className="mx-auto w-fit">
            <AudioPlayer src="/assets/chemie.m4a" />
          </div>

          <Paragraph center>
            Úkolem oboru „Obecné Harmonie Vztahovostí“ je zachovat či probouzet
            v mládeži citovost při práci s materií. Proto hledám ty lidi, kteří
            mají srdce otevřené pro hledání souvislostí a zároveň jsou alespoň
            částeční znalci oborů chemie, fyziky, matematiky aj.
          </Paragraph>

          <Paragraph center>
            Jsou to právě tyto oblasti, které mladým lidem často zúžily obzor do
            rozumového vězení.
          </Paragraph>

          <Paragraph center>
            Naším úsilím je pokusit se osvobodit a oživit pojmy v nich obsažené
            tak, aby se co nejvíce z nich dostalo svého duchovní opodstatnění.
          </Paragraph>

          <Paragraph center>
            Každému pojmu či procesu mezi jednotlivými částečkami Země, se má
            najít podobnost se základními vlastnostmi člověka či mezilidskými
            vztahy. A to tak, aby byly pro náš cit zřejmé.
          </Paragraph>

          <Paragraph center>
            Také co nejvíce vědních pojmů musí být citem znatelných a nemají
            zůstat pouze prázdným naučeným výrazem - logicky odvislým od dalšího
            pojmu. Podobně pracuje už psychosomatika, permakultura, bylinkáři
            při léčbě nemocí či přírodní znalci jako byli nebo jsou Peter
            Wohlleben, Viktor Schauberger, Dr. Pollack a další.
          </Paragraph>

          <Paragraph center>
            My se však chceme také pokusit vdechnout do vědních oborů život tak,
            aby se v mládeži při kontaktu s pojmy vytvořil vždy i živý obraz z
            jejich denního života. Tím by se v ní pěstovala zodpovědnost při
            jakémkoliv slučování, spojování vlastností - podloženém svědomím. A
            při objevování vědy a vynálezů by se vycházelo z citového podhoubí.
            Citovost spojená s vědou by probouzela i pro rozum představitelnou
            víru k Dokonalosti, která stvořila tento svět.
          </Paragraph>

          <Subtitle center>
            Celý svět nejen hmotný, ale i ten vztahový, nehmatatelný, je
            sloučením rozličných povah
          </Subtitle>

          <Paragraph center>
            Všichni víme, že základní stavební jednotkou hmoty je jeden atom o
            určité povaze. A ve svých sloučeninách se vytváří nám viditelný svět
            z miliónů jeho částí, k prvkům a molekulám až k výstavbě celého
            našeho těla a světa kolem nás. Ony konkrétní atomy, prvky či
            molekuly v sobě však nesou vlastnosti v různých mírách podobné těm
            lidským.
          </Paragraph>

          <Paragraph center>
            A tak i svět projevu a charakteru duše jako osobnosti, je taktéž
            sloučeninami miliónů těch nejzákladnějších částeček „atomů“ o
            určitých vlastnostech. Čím je cit člověka méně zatížený rozumem, 
            tím dokáže hlouběji nahlédnout do světa slučování až k
            původní jedné základní částečce – jednoho „atomu“ též o určité
            vlastnosti.
          </Paragraph>

          <Paragraph center>
            Avšak oněch „atomů“ je v jedné lidské složené vlastnosti stovky,
            tisíce, ba i milióny. A jen onen cit je schopen, tím jak moc je
            čistý, pracovat s určitým množstvím částeček v mezilidských
            vztazích. Čím je omezenější rozumem, tím užší pole částí je schopen
            vnímat.
          </Paragraph>

          <Paragraph center>
            Když si chceme vyléčit tělo, jdeme si do lékárny pro prvky sloučenin
            (vitamíny). Všichni tak prvně ale vyhledáme lékaře, aby nám
            předepsal zcela ony jisté prvky v konkrétních množství (míře) pro
            problém v dané oblasti. Lékař vždy vše předepisuje tak, aby
            nenarušil běh zase jiné sloučeniny (oblasti) neúměrným množstvím a
            nenastolil nesoulad – nemoc.
          </Paragraph>

          <Paragraph center>
            Víme tak, že nedostatek či přemíra jednoho prvku v určité oblasti na
            těle souvisí s konkrétní oblastí našeho osobního života. Tedy každý
            prvek v sobě nese jistou vlastnost o různé míře, ať už jako prvek
            nejen pro tělo, ale i pro duši.
          </Paragraph>

          <Paragraph center>
            Doplníme-li tak správné množství „vitamínů“ – tedy sloučenin
            vlastností - i do svého charakteru osobnosti, respektive úměrně se v
            něčem změníme, uzdravíme se na duši i na těle. Ono doplňování je
            však jedinečné pro každého jednotlivého člověka a danou přítomnost.
          </Paragraph>

          <Paragraph center>
            Celé naše tělo je tak sloučeninami dosažených vlastností prvků za
            všechny životy. A právě nyní je svým výsledkem zcela odpovídající
            oněm sloučeninám našich jednotlivých povahových rysů.
          </Paragraph>

          <Paragraph center>
            Tudíž každý jeden atom, prvek i molekula našeho charakteru je vrytá
            na našem fyzickém vzhledu. Všímejte si proto, jaké prvky a jaká míra
            chybí nejen Vašemu tělu, ale i duši. Znenáhla zjistíte, že jsou si
            oba stavy vesměs velmi podobné.
          </Paragraph>

          <Paragraph center>
            Například každé pracovní odvětví přitahuje zákonem přitažlivosti
            jisté charakterové osoby. Dokonce jednotlivé firmy zase ve své
            „molekule“ konkrétního odvětví přitahují ještě konkrétnější povahové
            rysy lidí. Tudíž v sobě hmotnostně nesou podobné míry prvků nejen
            skladby těla, ale i vlastností jejich zaměstnanců. A tak můžeme jít
            do hloubky dál a prozkoumávat tak duševní přitahování, odrážející se
            v tom fyzikálním.
          </Paragraph>

          <Paragraph center>
            Jdeme tak slučováním jednotlivých uskupení (molekul) až k celé
            osobnosti člověka, partnerského vztahu, rodině, městu, kraje až k
            národům. Dále pak od Země k planetám a celistvého obrazu celku
            sloučeného z četných jednotlivostí. A až poté ve své kráse slučování
            jsme teprve schopni se dotknout krásy obrazu Dokonalosti, která
            stvořila tento svět.
          </Paragraph>

          <Paragraph center>
            Přáli bychom si tak postupně objasňovat nejen co nejvíce chemických
            vazeb a procesů a připodobňovat je běžnému lidskému životu. Neboť
            každá část hmoty a jednotlivé procesy nesou v sobě i živý obraz
            slučování povah.
          </Paragraph>

          <Subtitle center>
            Jen slučováním jednotlivostí lze dospět k dokonalosti
          </Subtitle>

          <Paragraph center>
            V době Velkého třesku sem přicházel jeden atom za druhým, prvek za
            prvkem a postupně tu tak začaly vznikat složitější a složitější
            sloučeniny. Každý prvek v sobě nese svou jedinečnou skladbu povah
            podobných těm lidským. Množství a složení atomů v jednom prvku udává
            jeho vlastnost.
          </Paragraph>

          <Paragraph center>
            Mendělejova tabulka prvků je tak určitou učebnicí už jistých odstínů
            složených z jednotlivých krás. A jelikož prvek je už sám o sobě
            sloučeninou, je teprve atom tou nejzákladnější částečkou jednotlivé
            krásy.
          </Paragraph>

          <Paragraph center>
            Zde je naším přáním jednoho dne vytvořit onu tabulku v živých
            obrazech, které budou svou podobností přidruženy jednotlivým prvkům.
            Stejně tak, jako v sobě nese určité povahy křestní jméno člověka,
            rodina, město, kraje a státy, národy a planety. Vše je sloučením
            jednotlivých rozličných vlastností.
          </Paragraph>

          <Subtitle center>
            Chemie může v mládeži utužovat chápání krásy celku
          </Subtitle>

          <Subtitle center>
            Má podávat vědomí o tom, že každá jedna část naší osobnosti je
            sloučena z tisíce dalších částeček a podčástí - v rozličných
            povahách a o různých mírách
          </Subtitle>

          <Paragraph center>
            Každá vteřina lidského života je sloučeninou miliónů prvků
            vlastností ve vzájemných procesech při jakékoliv běžné komunikaci.
            Chemie tak může probouzet v lidech víru v dokonalost toho, kdo
            stvořil tento svět. Také podporuje krásu výstavby celku a úctu k
            jednotlivostem. A vědomí toho, že jedna část je složená zase z
            dalších v nás má utužovat pokoru.
          </Paragraph>

          <Paragraph center>
            Dejme tomu obyčejné jedno lidské slovo. Už jen toto slovo v sobě
            nese jisté sloučeniny jako tón, barva hlasu, jeho výšku a hloubku,
            intonaci, s tím souběžnou mimiku a mnoho dalšího. Každá tato
            složenina v sobě ale obsahuje spousty a spousty dalších prvků
            vlastností v různém množství, až se nakonec dostaneme k jednotce
            atomu – jedné základní částečce jedné povahy. Takových částeček
            citem rozpoznatelných jsou v charakteru naší osobnosti milióny.
          </Paragraph>

          <Paragraph center>
            Všimněte si, kolik lidé vynakládají úsilí, aby z jednotlivých atomů
            dovedli vše ke hmotné dokonalosti až k danému výrobku. Jak málo
            úsilí po citovosti však vkládají do toho, aby do dané své konkrétní
            životní situace zařadili správné množství vlastností atomů v prvcích
            a sloučeninách tak, aby vše stálo ve stejné rovnováze s okolím.
            Stejně tak, jako se snaží v úspěšném dosažení pracovních cílů pro přežití na
            Zemi.
          </Paragraph>

          <Paragraph center>
            Úkolem citu člověka je však vyváženě skládat vztahy – slučovat ony
            jednotlivé základní částečky krás do molekul a poté je nakonec i
            vyváženě spojovat vztahově s okolím. A tak, jako to dokáže obor
            chemie a logika člověka v oblasti materií, tak jeho cit to umí zase
            na rovině osobnostní - duše.
          </Paragraph>

          <Paragraph center>
            Jak všichni víme, krása roste s doplňováním či slučováním i těch
            nejjemnějších jednotlivostí. Stejné je to v partnerských vztazích. A
            byl-li by člověk zralý, zvládal by to i v přátelství a známostech.
          </Paragraph>

          <Subtitle center>
            On zde ale hledá sobě blízké, jelikož se nechce namáhat a hledat
            opravdovou krásu
          </Subtitle>

          <Paragraph center>
            Ze školy známe, že se ve fyzice + a – přitahují. Kdežto náboje
            záporné – a – či + a + se odpuzují. Tím, že jsme se trochu
            přiblížili pojmům v živých obrazech, můžeme pak pokračovat příště
            dále. Atom, jak známe v sobě obsahuje protony (+) elektrony (-) a
            neutrony bez náboje. Pojďme spolu objevovat chemii, ale i další
            obory v podobnostech s naším běžným životem.
          </Paragraph>

          <Subtitle center>
            Pokud Vás toto téma zajímá a chcete s námi hledat další souvislosti
            nejen v tomto oboru, ozvěte se v kontaktech na těchto stránkách
          </Subtitle>

          <Paragraph center>
            Zatím však máme v zásobě jen velmi málo vědění. Proto prosíme o
            pomoc ty, kteří
          </Paragraph>

          <Subtitle center>
            mají též zájem o to, aby ono živoucí vědění bylo základem pro práci
            s logikou
          </Subtitle>

          <Paragraph center>
            Další část do úvodu do oboru „Obecné Harmonie Vztahovostí“ vyjde
            zase za měsíc 7.2.2025
          </Paragraph>

          <Paragraph center>s názvem</Paragraph>

          <Title center>
            2.část <br />
            Svět slučování <br />
            aneb <br />
            Fyzikální a duševní doplňování
          </Title>
        </ContentWrapper>
      </div>
    </Page>
  );
};
